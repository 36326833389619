export default function FailedBar({ failedGuess }) {
  return (
    <div className="flex h-9 items-center border border-gray-300 p-2 dark:border-gray-500">
      <div className="mr-2">
        <svg
          className="text-custom-negative ml-0.5"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="red"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </div>
      <div className="flex flex-1 items-center justify-between">
        <div className="text-sm font-bold text-gray-500 ">
          <span>{failedGuess}</span>
        </div>
      </div>
    </div>
  )
}

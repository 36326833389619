export default function SkipBar() {
  return (
    <div className="flex h-9 items-center border border-gray-300 p-2 dark:border-gray-500">
      <div className="mr-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="gray"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M13 17l5-5-5-5M6 17l5-5-5-5" />
        </svg>
      </div>
      <div className="flex flex-1 items-center justify-between">
        <div className="text-sm font-bold text-gray-500">
          <span>SKIPPED</span>
        </div>
      </div>
    </div>
  )
}

const gameSchedule = {
  125: 20,
  126: 918,
  127: 1535,
  128: 20899,
  129: 11061,
  130: 32182,
  131: 50265,
  132: 35507,
  133: 31964,
  134: 37999,
  135: 11757,
  136: 9253,
  137: 23273,
  138: 40748,
  139: 24833,
  140: 20583,
  141: 37779,
  142: 269,
  143: 28171,
  144: 9989,
  145: 37450,
  146: 38691,
  147: 14719,
  148: 30276,
  149: 29803,
  150: 34572,
  151: 34933,
  152: 205,
  153: 42897,
  154: 39535,
  155: 22319,
  156: 16498,
  157: 31240,
  158: 23755,
  159: 22199,
  160: 9919,
  161: 18679,
  162: 35849,
  163: {
    id: 37999,
    audioNum: 2,
  },
  164: 21,
  165: 14813,
  166: 34599,
  167: 11771,
  168: 37520,
  169: 37430,
  170: 48736,
  171: 42249,
  172: 36296,
  173: 34566,
  174: {
    id: 31964,
    audioNum: 2,
  },
  175: 4224,
  176: 31043,
  177: 28223,
  178: 1575,
  179: 199,
  180: 6702,
  181: {
    id: 34572,
    audioNum: 2,
  },
  182: 20507,
  183: 37521,
  184: 32281,
  185: 11061,
  186: {
    id: 40748,
    audioNum: 2,
  },
  187: 38000,
  188: {
    id: 14719,
    audioNum: 2,
  },
  189: {
    id: 31240,
    audioNum: 2,
  },
  190: {
    id: 37999,
    audioNum: 3,
  },
  191: {
    id: 42249,
    audioNum: 2,
  },
  192: {
    id: 24833,
    audioNum: 2,
  },
  193: 2001,
  194: {
    id: 21,
    audioNum: 2,
  },
  195: {
    id: 20,
    audioNum: 2,
  },
  196: {
    id: 20583,
    audioNum: 2,
  },
}

module.exports = gameSchedule
